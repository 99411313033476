// Styles
import "./index.styl"
// External assets
import { Button } from "@framework"
// Components
import Carousel from "./component/carousel/carousel"
// Templates
import Main from "./template/main/main.js"
import Header from "./template/header/header.js"

// Init before DOMContentLoaded
Main.init()

document.addEventListener("DOMContentLoaded", () => {
    // Components
    Button.init()
    Carousel.init()
    // Templates
    Header.init()
})
