import scrollIntoView from "scroll-into-view-if-needed"

const header = {}

header.init = () => {
    header.rwdMenu()
    header.menuScroll()
    header.linkScroll()
}

header.rwdMenu = () => {
    const $toggle = document.querySelector(".-js-menu-toggle")

    if (!$toggle) {
        return
    }

    $toggle.addEventListener("click", (e) => {
        e.preventDefault()

        if (document.body.classList.contains("-no-scroll")) {
            document.body.classList.remove("-no-scroll", "-show-menu")
        } else {
            document.body.classList.add("-no-scroll", "-show-menu")
        }

        return false
    })
}

header.linkScroll = () => {
    const $links = document.querySelectorAll("a[href*='#menu-']")
    const linkHandler = function(e) {
        e.preventDefault()

        const target = this.getAttribute("href")
        const $target = document.querySelector(target)
        const $header = document.querySelector(".t-Header")

        if (!$target || !$header) {
            return
        }

        document.body.classList.remove("-no-scroll", "-show-menu")

        const big = window.innerHeight < $target.offsetHeight

        scrollIntoView($target, {
            block: big ? "start" : "center",
            inline: "nearest",
            behavior: (actions) =>
                actions.forEach(({ el, top, left }, i) => {
                    el.scrollTop = big ? top - $header.clientHeight : top
                    el.scrollLeft = left
                }),
        })

        return false
    }

    for (const $link of $links) {
        $link.addEventListener("click", linkHandler)
    }
}

header.menuScroll = () => {
    const offset = window.innerHeight / 2
    const $menuItems = document.querySelectorAll(".t-Header__menu_item")
    const $topbar = document.querySelector(".t-Topbar")
    const topbarHeight = $topbar ? $topbar.offsetHeight : 0
    const $sections = [...document.querySelectorAll(".-js-section")]

    window.addEventListener("scroll", () => {
        let top = window.pageYOffset || document.documentElement.scrollTop
        let removeActive = () => {
            const $active = document.querySelector(".t-Header__menu_item.-active")

            if ($active) {
                $active.classList.remove("-active")
            }
        }

        for (const $section of $sections) {
            const sectionTop = $section.offsetTop

            if (sectionTop <= top + offset) {
                const id = $section.id
                const $next = document.querySelector(`.t-Header__menu_item a[href='#${id}']`)

                if (!$next) {
                    continue
                }

                removeActive()

                $next.parentElement.classList.add("-active")
            }
        }

        if (top > topbarHeight) {
            document.body.classList.add("-fixed-menu")
        } else {
            document.body.classList.remove("-fixed-menu")
            removeActive()
        }
    })
}

export default header
