import Flickity from "flickity"
import "flickity/css/flickity.css"

const carousel = {}

carousel.init = () => {
    const $carousel = document.querySelector(".-js-carousel")

    if (!$carousel || $carousel.querySelectorAll(".c-Carousel__item").length < 2) {
        return
    }

    $carousel.classList.add("-js-upgraded")

    const options = {
        autoPlay: 7000,
        pauseAutoPlayOnHover: false,
        prevNextButtons: false,
        selectedAttraction: 0.03,
        friction: 0.4,
    }

    new Flickity($carousel, options)
}

export default carousel
